<template>
    <component :is="form === undefined ? 'div' : 'b-card'">
        <template>
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <b-col cols="12">
                        <b-media no-body>
                            <b-media-body class="mt-75 ml-75">
                                <b-row class="row justify-content-center">
                                    <b-col cols="12" sm="4">
                                        <!-- upload button -->

                                        <image-cropper v-model="fileForm" label="manager image" :url.sync="form.thumbnail" />
                                        <!--/ upload button -->
                                    </b-col>
                                </b-row>
                                <b-card-text class="row justify-content-center">Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                            </b-media-body>
                        </b-media>
                    </b-col>
                    <b-row class="mt-3">
                        <!-- Name -->
                        <b-col cols="12" sm="6" md="4">
                            <b-form-group label="Name" label-for="name">
                                <b-form-input id="name" v-model="form.name" placeholder="type slideshow name" />
                            </b-form-group>
                        </b-col>
                        <!-- Arabic Name -->
                        <b-col cols="12" sm="6" md="4">
                            <b-form-group label="Type" label-for="type">
                                <v-select :disabled="unEditable" v-model="form.coming_soonable_type" :options="sourceOption" label="type" input-id="type" placeholder="Please first select the type" @input="fetchTypeList(form.coming_soonable_type)" />
                            </b-form-group>
                        </b-col>
                        <b-col v-if="form.coming_soonable_type == 'music'" cols="12" sm="6" md="4">
                            <b-form-group label="Music" label-for="type">
                                <v-select :disabled="unEditable" v-model="form.coming_soonable_id" :options="musicOption" label="name" input-id="type" placeholder="Choose the music" :loading="isLoading" @search="filterMusic" :reduce="(val) => val.id" />
                            </b-form-group>
                        </b-col>
                        <b-col v-if="form.coming_soonable_type == 'artist'" cols="12" sm="6" md="4">
                            <b-form-group label="Artists" label-for="type">
                                <v-select :disabled="unEditable" v-model="form.coming_soonable_id" :options="artistOption" value="id" label="name" input-id="type" placeholder="Choose the artist" :loading="isLoading" @search="filterArtist" :reduce="(val) => val.id" />
                            </b-form-group>
                        </b-col>
                        <b-col v-if="form.coming_soonable_type == 'video'" cols="12" sm="6" md="4">
                            <b-form-group label="Videos" label-for="type">
                                <v-select :disabled="unEditable" v-model="form.coming_soonable_id" :options="videoOption" label="name" input-id="type" placeholder="Choose the video" :loading="isLoading" :reduce="(val) => val.id" />
                            </b-form-group>
                        </b-col>
                        <b-col v-if="form.coming_soonable_type == 'playlist'" cols="12" sm="6" md="4">
                            <b-form-group label="Playlists" label-for="type">
                                <v-select :disabled="unEditable" v-model="form.coming_soonable_id" :options="playlistOption" label="name" input-id="type" placeholder="Choose the playlist" :loading="isLoading" :reduce="(val) => val.id" />
                            </b-form-group>
                        </b-col>
                        <b-col v-if="form.coming_soonable_type == 'composer'" cols="12" sm="6" md="4">
                            <b-form-group label="Composer" label-for="type">
                                <v-select :disabled="unEditable" v-model="form.coming_soonable_id" :options="composerOption" label="name" input-id="type" placeholder="Choose the composer" :loading="isLoading" :reduce="(val) => val.id" />
                            </b-form-group>
                        </b-col>
                        <b-col v-if="form.coming_soonable_type == 'advertise'" cols="12" sm="6" md="4">
                            <b-form-group label="Advertise" label-for="type">
                                <b-form-input id="name" v-model="form.link" placeholder="Enter the Advertise Link" :loading="isLoading" />
                            </b-form-group>
                        </b-col>
                        <b-col v-if="form.coming_soonable_type == 'coming_soons'" cols="12" sm="6" md="4">
                            <b-form-group label="Coming Soon" label-for="coming-soon">
                                <b-form-input id="coming-soon" v-model="form.link" placeholder="Enter the Coming Soon Link" :loading="isLoading" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="row justify-content-center">
                        <!-- Form Actions -->
                        <b-col cols="12">
                            <div class="d-flex mt-2">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" type="submit">
                                    {{ slideShowId ? "update" : "add" }}
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </template>
    </component>
</template>

<script>
import { BFormCheckbox, BFormSelect, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import useSlideShowList from "./useSlideShowList";
import useFile from "../../file/useFile";
import ArtistRepository from "@/abstraction/repository/artistRepository";
import MusicRepository from "@/abstraction/repository/musicRepository";
import VideoRepository from "@/abstraction/repository/videoRepository";
import PlaylistRepository from "@/abstraction/repository/playListRepository";
import ComposerRepository from "@/abstraction/repository/composerRepository";

const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        BTab,
        BFormInput,
        BFormCheckbox,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    setup() {
        let blankForm = {
            name: "",
            coming_soonable_type: "",
            coming_soonable_id: null,
            link: null,
            file_batch_id: RANDOM_TOKEN,
        };
        const artistRepository = new ArtistRepository();
        const musicRepository = new MusicRepository();
        const videoRepository = new VideoRepository();
        const playlistRepository = new PlaylistRepository();
        const composerRepository = new ComposerRepository();
        const slideShowId = ref(null);
        const isLoading = ref(false);
        const artistOption = ref(undefined);
        const musicOption = ref(undefined);
        const videoOption = ref(undefined);
        const playlistOption = ref(undefined);
        const composerOption = ref(undefined);
        const unEditable = ref(false);
        const { storeFile, fileForm } = useFile(RANDOM_TOKEN, "coming_soon", "coming_soons");
        const { storeSlideShow, updateSlideshow, showSlideshow } = useSlideShowList();
        const form = ref(JSON.parse(JSON.stringify(blankForm)));
        const sourceOption = ref(["artist", "music", "video", "playlist", "composer", "advertise", "coming_soons"]);
        const resetData = () => {
            form.value = JSON.parse(JSON.stringify(blankForm));
        };
        const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);
        if (router.currentRoute.params.id) {
            slideShowId.value = router.currentRoute.params.id;
            unEditable.value = true;
        }

        // fill Form When edit page
        const showData = async () => {
            const response = await showSlideshow(slideShowId.value);
            if (response) {
                form.value = { ...blankForm, ...response };
                blankForm = { ...blankForm, ...response };
                fetchTypeList(response.coming_soonable_type);
                console.log(response);
                console.log(form.value);
            } else {
                form.value = undefined;
            }
        };

        if (slideShowId.value) {
            showData();
        }

        const onSubmit = async () => {
            console.log(form.value);
            if (form.value.coming_soonable_type == "advertise") {
                form.value.coming_soonable_id = null;
            } else if (form.value.coming_soonable_type == "coming_soons") {
                form.value.coming_soonable_id = null;
            } else {
                form.value.link = null;
            }
            //
            if (fileForm.value.file) {
                await storeFile();
            }
            if (slideShowId.value) {
                updateSlideshow(slideShowId.value, form.value);
            } else {
                storeSlideShow(form.value);
            }
            router.push({ name: 'apps-comming-soon-list' })
        };

        const fetchTypeList = (type) => {
            if (type == "artist") {
                fetchArtists();
            } else if (type == "music") {
                fetchMusics();
            } else if (type == "video") {
                fetchVideos();
            } else if (type == "playlist") {
                fetchPlaylist();
            } else if (type == "composer") {
                fetchComposer();
            }
        };

        const filterArtist = (search) => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            isLoading.value = true;
            try {
                fetchArtists(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
                isLoading.value = false;
            }
        };
        const filterMusic = (search) => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            isLoading.value = true;
            try {
                fetchMusics(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
                isLoading.value = false;
            }
        };
        const filterVideo = (search) => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            isLoading.value = true;
            try {
                fetchVideos(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
                isLoading.value = false;
            }
        };
        const filterComposer = (search) => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            isLoading.value = true;
            try {
                fetchComposer(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
                isLoading.value = false;
            }
        };
        const fetchArtists = async (param = {}) => {
            const resource = await artistRepository.index(param);
            artistOption.value = resource.data;
        };
        const fetchMusics = async (param = {}) => {
            const resource = await musicRepository.index(param);
            musicOption.value = resource.data;
        };
        const fetchVideos = async (param = {}) => {
            const resource = await videoRepository.index(param);
            videoOption.value = resource.data;
        };
        const fetchPlaylist = async (param = {}) => {
            const resource = await playlistRepository.index(param);
            playlistOption.value = resource.data;
        };
        const fetchComposer = async (param = {}) => {
            const resource = await composerRepository.index(param);
            composerOption.value = resource.data;
        };
        return {
            form,
            fileForm,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            required,
            slideShowId,
            sourceOption,
            isLoading,
            fetchTypeList,
            artistOption,
            musicOption,
            videoOption,
            playlistOption,
            composerOption,
            filterArtist,
            filterMusic,
            filterVideo,
            unEditable,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
